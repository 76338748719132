import React from 'react';
import { Container, Col } from 'react-materialize';
import { COLOR, LABEL, SIZE, SCREEN_MEDIA } from '../../utils/constants.js';
import Row from '../organism/row.jsx';
import Divider from '../atoms/divider.jsx'
import Card from '../molecules/card.jsx';
import Banner from '../organism/banner.jsx';
import Path from '../molecules/path.jsx';
import Contact from '../organism/contact.jsx'

import PathIcPhoneColored from '../../icons/ic_phone_colored.svg';
import PathIcWAppColored from '../../icons/ic_wapp_colored.svg';
import PathIcEmailColored from '../../icons/ic_email_colored.svg';


const BodyContact = () => {
    return (
        <div>
            <Banner
                title={LABEL.contact}/>

            <Container>

                <Row
                    margin='0 0'>
                    <Path 
                        endpoint={LABEL.contact}
                        margin='20px 0' />
                </Row>

                <Row className='valign-wrapper'>
                    <Col s={12} m={12} l={12} xl={10} offset='xl1'>
                        <Contact/>
                    </Col>
                </Row>

                <Divider/>
                
                <Row>
                    <Col s={12} m={12} l={4} xl={4}>
                        <Card
                            ic_path={PathIcPhoneColored}
                            title='Teléfonos'
                            title_color={COLOR.grayDark}
                            title_separation='15px'
                            descs={[LABEL.phone_number_01]}
                            desc_color={COLOR.grayDark}
                            alignment='center'
                            margin='10px'
                            />
                    </Col>
                    <Col s={12} m={12} l={4} xl={4}>
                        <Card
                            ic_path={PathIcWAppColored}
                            title='WhatsApp'
                            title_color={COLOR.grayDark}
                            title_separation='15px'
                            descs={[`+51 ${LABEL.phone_number_01}`]}
                            desc_color={COLOR.grayDark}
                            alignment='center'
                            margin='10px'
                            />
                    </Col>
                    <Col s={12} m={12} l={4} xl={4}>
                        <Card
                            ic_path={PathIcEmailColored}
                            title='Correos'
                            title_color={COLOR.grayDark}
                            title_separation='15px'
                            descs={['admin@joeeventos.com']}
                            desc_color={COLOR.grayDark}
                            alignment='center'
                            margin='10px'
                            />
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default BodyContact;