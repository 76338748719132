
import { CATEGORY, THEMATIC } from './constants.js';

import PathImgBirth01_001 from '../images_categories/birth01/img_001.jpg';
import PathImgBirth01_002_001 from '../images_categories/birth01/img_002_001.jpg';
import PathImgBirth01_002_002 from '../images_categories/birth01/img_002_002.jpg';
import PathImgBirth01_003_001 from '../images_categories/birth01/img_003_001.jpg';
import PathImgBirth01_003_002 from '../images_categories/birth01/img_003_002.jpg';
import PathImgBirth01_003_003 from '../images_categories/birth01/img_003_003.jpg';
import PathImgBirth01_004_001 from '../images_categories/birth01/img_004_001.jpg';
import PathImgBirth01_004_003 from '../images_categories/birth01/img_004_003.jpg';
import PathImgBirth01_004_004 from '../images_categories/birth01/img_004_004.jpg';
import PathImgBirth01_005_001 from '../images_categories/birth01/img_005_001.jpg';
import PathImgBirth01_005_004 from '../images_categories/birth01/img_005_004.jpg';
import PathImgBirth01_006_001 from '../images_categories/birth01/img_006_001.jpg';
import PathImgBirth01_006_002 from '../images_categories/birth01/img_006_002.jpg';
import PathImgBirth01_006_003 from '../images_categories/birth01/img_006_003.jpg';
import PathImgBirth01_007_001 from '../images_categories/birth01/img_007_001.jpg';
import PathImgBirth01_007_002 from '../images_categories/birth01/img_007_002.jpg';
import PathImgBirth01_007_003 from '../images_categories/birth01/img_007_003.jpg';
import PathImgBirth01_007_005 from '../images_categories/birth01/img_007_005.jpg';
import PathImgBirth01_007_009 from '../images_categories/birth01/img_007_009.jpg';
import PathImgBirth01_008_001 from '../images_categories/birth01/img_008_001.jpg';
import PathImgBirth01_008_002 from '../images_categories/birth01/img_008_002.jpg';
import PathImgBirth01_008_003 from '../images_categories/birth01/img_008_003.jpg';
import PathImgBirth01_008_004 from '../images_categories/birth01/img_008_004.jpg';
import PathImgBirth01_009_001 from '../images_categories/birth01/img_009_001.jpg';
import PathImgBirth01_009_002 from '../images_categories/birth01/img_009_002.jpg';
import PathImgBirth01_009_003 from '../images_categories/birth01/img_009_003.jpg';
import PathImgBirth01_009_004 from '../images_categories/birth01/img_009_004.jpg';
import PathImgBirth01_009_007 from '../images_categories/birth01/img_009_007.jpg';
import PathImgBirth01_016 from '../images_categories/birth01/img_016.jpg';
import PathImgBirth01_025 from '../images_categories/birth01/img_025.jpg';
import PathImgBirth01_028 from '../images_categories/birth01/img_028.jpg';
import PathImgBirth01_032 from '../images_categories/birth01/img_032.jpg';


import PathImgBirth0214_001_001 from '../images_categories/birth0214/img_001_001.jpg';
import PathImgBirth0214_001_002 from '../images_categories/birth0214/img_001_002.jpg';
import PathImgBirth0214_001_003 from '../images_categories/birth0214/img_001_003.jpg';
import PathImgBirth0214_003_001 from '../images_categories/birth0214/img_003_001.jpg';
import PathImgBirth0214_003_002 from '../images_categories/birth0214/img_003_002.jpg';
import PathImgBirth0214_003_006 from '../images_categories/birth0214/img_003_006.jpg';
import PathImgBirth0214_003_007 from '../images_categories/birth0214/img_003_007.jpg';
import PathImgBirth0214_003_008 from '../images_categories/birth0214/img_003_008.jpg';
import PathImgBirth0214_003_010 from '../images_categories/birth0214/img_003_010.jpg';
import PathImgBirth0214_004_001 from '../images_categories/birth0214/img_004_001.jpg';
import PathImgBirth0214_004_002 from '../images_categories/birth0214/img_004_002.jpg';
import PathImgBirth0214_004_003 from '../images_categories/birth0214/img_004_003.jpg';
import PathImgBirth0214_004_004 from '../images_categories/birth0214/img_004_004.jpg';
import PathImgBirth0214_005 from '../images_categories/birth0214/img_005.jpg';
import PathImgBirth0214_006_001 from '../images_categories/birth0214/img_006_001.jpg';
import PathImgBirth0214_006_002 from '../images_categories/birth0214/img_006_002.jpg';
import PathImgBirth0214_007 from '../images_categories/birth0214/img_007.jpg';
import PathImgBirth0214_008_001 from '../images_categories/birth0214/img_008_001.jpg';
import PathImgBirth0214_008_002 from '../images_categories/birth0214/img_008_002.jpg';
import PathImgBirth0214_008_003 from '../images_categories/birth0214/img_008_003.jpg';
import PathImgBirth0214_009 from '../images_categories/birth0214/img_009.jpg';
import PathImgBirth0214_010 from '../images_categories/birth0214/img_010.jpg';
import PathImgBirth0214_011 from '../images_categories/birth0214/img_011.jpg';
import PathImgBirth0214_012 from '../images_categories/birth0214/img_012.jpg';
import PathImgBirth0214_013 from '../images_categories/birth0214/img_013.jpg';
import PathImgBirth0214_014_001 from '../images_categories/birth0214/img_014_001.jpg';
import PathImgBirth0214_014_002 from '../images_categories/birth0214/img_014_002.jpg';
import PathImgBirth0214_014_003 from '../images_categories/birth0214/img_014_003.jpg';
import PathImgBirth0214_015_001 from '../images_categories/birth0214/img_015_001.jpg';
import PathImgBirth0214_015_003 from '../images_categories/birth0214/img_015_003.jpg';
import PathImgBirth0214_015_004 from '../images_categories/birth0214/img_015_004.jpg';
import PathImgBirth0214_016_001 from '../images_categories/birth0214/img_016_01.jpg';
import PathImgBirth0214_016_003 from '../images_categories/birth0214/img_016_03.jpg';
import PathImgBirth0214_016_005 from '../images_categories/birth0214/img_016_05.jpg';
import PathImgBirth0214_017_001 from '../images_categories/birth0214/img_017_001.jpg';
import PathImgBirth0214_017_002 from '../images_categories/birth0214/img_017_002.jpg';
import PathImgBirth0214_017_004 from '../images_categories/birth0214/img_017_004.jpg';
import PathImgBirth0214_018_001 from '../images_categories/birth0214/img_018_001.jpg';
import PathImgBirth0214_018_002 from '../images_categories/birth0214/img_018_002.jpg';
import PathImgBirth0214_018_004 from '../images_categories/birth0214/img_018_004.jpg';
import PathImgBirth0214_018_006 from '../images_categories/birth0214/img_018_006.jpg';
import PathImgBirth0214_019 from '../images_categories/birth0214/img_019.jpg';
import PathImgBirth0214_021 from '../images_categories/birth0214/img_021.jpg';
import PathImgBirth0214_022 from '../images_categories/birth0214/img_022.jpg';
import PathImgBirth0214_023 from '../images_categories/birth0214/img_023.jpg';
import PathImgBirth0214_024 from '../images_categories/birth0214/img_024.jpg';
import PathImgBirth0214_025_001 from '../images_categories/birth0214/img_025_01.jpg';
import PathImgBirth0214_025_003 from '../images_categories/birth0214/img_025_03.jpg';
import PathImgBirth0214_026 from '../images_categories/birth0214/img_026.jpg';
import PathImgBirth0214_028_001 from '../images_categories/birth0214/img_028_01.jpg';
import PathImgBirth0214_029_001 from '../images_categories/birth0214/img_029_001.jpg';
import PathImgBirth0214_029_002 from '../images_categories/birth0214/img_029_002.jpg';
import PathImgBirth0214_029_003 from '../images_categories/birth0214/img_029_003.jpg';
import PathImgBirth0214_030 from '../images_categories/birth0214/img_030.jpg';
import PathImgBirth0214_032_001 from '../images_categories/birth0214/img_032_01.jpg';
import PathImgBirth0214_032_002 from '../images_categories/birth0214/img_032_02.jpg';
import PathImgBirth0214_032_003 from '../images_categories/birth0214/img_032_03.jpg';
import PathImgBirth0214_032_004 from '../images_categories/birth0214/img_032_04.jpg';
import PathImgBirth0214_033 from '../images_categories/birth0214/img_033.jpg';
import PathImgBirth0214_034_003 from '../images_categories/birth0214/img_034_003.jpg';
import PathImgBirth0214_034_001 from '../images_categories/birth0214/img_034_001.jpg';
import PathImgBirth0214_034_002 from '../images_categories/birth0214/img_034_002.jpg';
import PathImgBirth0214_035_001 from '../images_categories/birth0214/img_035_001.jpg';
import PathImgBirth0214_035_002 from '../images_categories/birth0214/img_035_002.jpg';
import PathImgBirth0214_035_003 from '../images_categories/birth0214/img_035_003.jpg';


import PathImgBirth15_001 from '../images_categories/birth15/img_001.jpg';
import PathImgBirth15_002 from '../images_categories/birth15/img_002.jpg';
import PathImgBirth15_003_001 from '../images_categories/birth15/img_003_01.jpg';
import PathImgBirth15_003_002 from '../images_categories/birth15/img_003_02.jpg';
import PathImgBirth15_004_001 from '../images_categories/birth15/img_004_01.jpg';
import PathImgBirth15_005_001 from '../images_categories/birth15/img_005_001.jpg';
import PathImgBirth15_006_001 from '../images_categories/birth15/img_006_001.jpg';
import PathImgBirth15_006_002 from '../images_categories/birth15/img_006_002.jpg';
import PathImgBirth15_006_003 from '../images_categories/birth15/img_006_003.jpg';
import PathImgBirth15_006_007 from '../images_categories/birth15/img_006_007.jpg';


import PathImgBirth50G_001_001 from '../images_categories/birth50G/img_001_001.jpg';
import PathImgBirth50G_001_002 from '../images_categories/birth50G/img_001_002.jpg';
import PathImgBirth50G_001_003 from '../images_categories/birth50G/img_001_003.jpg';
import PathImgBirth50G_001 from '../images_categories/birth50G/img_001.jpg';
import PathImgBirth50G_002_001 from '../images_categories/birth50G/img_002_001.jpg';
import PathImgBirth50G_002_002 from '../images_categories/birth50G/img_002_002.jpg';
import PathImgBirth50G_002_003 from '../images_categories/birth50G/img_002_003.jpg';
import PathImgBirth50G_002_004 from '../images_categories/birth50G/img_002_004.jpg';
import PathImgBirth50G_003_001 from '../images_categories/birth50G/img_003_001.jpg';
import PathImgBirth50G_003_002 from '../images_categories/birth50G/img_003_002.jpg';
import PathImgBirth50G_003_003 from '../images_categories/birth50G/img_003_003.jpg';
import PathImgBirth50G_003_004 from '../images_categories/birth50G/img_003_004.jpg';
import PathImgBirth50G_003_005 from '../images_categories/birth50G/img_003_005.jpg';
import PathImgBirth50G_003_006 from '../images_categories/birth50G/img_003_006.jpg';
import PathImgBirth50G_004 from '../images_categories/birth50G/img_004.jpg';
import PathImgBirth50G_005_001 from '../images_categories/birth50G/img_005_001.jpg';
import PathImgBirth50G_005_002 from '../images_categories/birth50G/img_005_002.jpg';
import PathImgBirth50G_006 from '../images_categories/birth50G/img_006.jpg';
import PathImgBirth50G_007_001 from '../images_categories/birth50G/img_007_001.jpg';
import PathImgBirth50G_007_002 from '../images_categories/birth50G/img_007_002.jpg';
import PathImgBirth50G_007_003 from '../images_categories/birth50G/img_007_003.jpg';
import PathImgBirth50G_007_004 from '../images_categories/birth50G/img_007_004.jpg';
import PathImgBirth50G_007_005 from '../images_categories/birth50G/img_007_005.jpg';
import PathImgBirth50G_009_004 from '../images_categories/birth50G/img_009_004.jpg';
import PathImgBirth50G_009_002 from '../images_categories/birth50G/img_009_002.jpg';
import PathImgBirth50G_010_001 from '../images_categories/birth50G/img_010_001.jpg';
import PathImgBirth50G_010_002 from '../images_categories/birth50G/img_010_002.jpg';
import PathImgBirth50G_011_001 from '../images_categories/birth50G/img_011_001.jpg';
import PathImgBirth50G_011_003 from '../images_categories/birth50G/img_011_003.jpg';



import PathImgBirth_001_001 from '../images_categories/birth/img_001_001.jpg';
import PathImgBirth_001_002 from '../images_categories/birth/img_001_002.jpg';
import PathImgBirth_001_003 from '../images_categories/birth/img_001_003.jpg';
import PathImgBirth_001_006 from '../images_categories/birth/img_001_006.jpg';
import PathImgBirth_002_001 from '../images_categories/birth/img_002_001.jpg';
import PathImgBirth_002_002 from '../images_categories/birth/img_002_002.jpg';
import PathImgBirth_002_003 from '../images_categories/birth/img_002_003.jpg';
import PathImgBirth_008 from '../images_categories/birth/img_008.jpg';
import PathImgBirth_014 from '../images_categories/birth/img_014.jpg';
import PathImgBirth_015 from '../images_categories/birth/img_015.jpg';
import PathImgBirth_017 from '../images_categories/birth/img_017.jpg';
import PathImgBirth_018 from '../images_categories/birth/img_018.jpg';
import PathImgBirth_020 from '../images_categories/birth/img_020.jpg';
import PathImgBirth_029 from '../images_categories/birth/img_029.jpg';
import PathImgBirth_031_001 from '../images_categories/birth/img_031.jpg';
import PathImgBirth_031_002 from '../images_categories/birth/img_031_02.jpg';
import PathImgBirth_036_001 from '../images_categories/birth/img_036_001.jpg';
import PathImgBirth_036_002 from '../images_categories/birth/img_036_002.jpg';
import PathImgBirth_036_003 from '../images_categories/birth/img_036_003.jpg';
import PathImgBirth_036_004 from '../images_categories/birth/img_036_004.jpg';


import PathImgCelebration001 from '../images_categories/celebrations/img_001.jpg';
import PathImgCelebration002 from '../images_categories/celebrations/img_002.jpg';
import PathImgCelebration003 from '../images_categories/celebrations/img_003.jpg';
import PathImgCelebration004 from '../images_categories/celebrations/img_004.jpg';
import PathImgCelebration005S00 from '../images_categories/celebrations/img_005_00.jpg';
import PathImgCelebration005S01 from '../images_categories/celebrations/img_005_01.jpg';
import PathImgCelebration005S02 from '../images_categories/celebrations/img_005_02.jpg';
import PathImgCelebration005S04 from '../images_categories/celebrations/img_005_04.jpg';
import PathImgCelebration006 from '../images_categories/celebrations/img_006.jpg';
import PathImgCelebration_008_001 from '../images_categories/celebrations/img_008_001.jpg';
import PathImgCelebration_008_002 from '../images_categories/celebrations/img_008_002.jpg';
import PathImgCelebration_008_004 from '../images_categories/celebrations/img_008_004.jpg';
import PathImgCelebration_009_001 from '../images_categories/celebrations/img_009_001.jpg';
import PathImgCelebration_009_003 from '../images_categories/celebrations/img_009_003.jpg';
import PathImgCelebration_010_001 from '../images_categories/celebrations/img_010_001.jpg';
import PathImgCelebration_010_002 from '../images_categories/celebrations/img_010_002.jpg';
import PathImgCelebration_010_003 from '../images_categories/celebrations/img_010_003.jpg';
import PathImgCelebration_011_001 from '../images_categories/celebrations/img_011_001.jpg';
import PathImgCelebration_011_002 from '../images_categories/celebrations/img_011_002.jpg';
import PathImgCelebration_011_003 from '../images_categories/celebrations/img_011_003.jpg';
import PathImgCelebration_011_007 from '../images_categories/celebrations/img_011_007.jpg';
import PathImgCelebration_012_001 from '../images_categories/celebrations/img_012_001.jpg';
import PathImgCelebration_012_002 from '../images_categories/celebrations/img_012_002.jpg';
import PathImgCelebration_013_001 from '../images_categories/celebrations/img_013_001.jpg';
import PathImgCelebration_013_002 from '../images_categories/celebrations/img_013_002.jpg';
import PathImgCelebration_013_003 from '../images_categories/celebrations/img_013_003.jpg';
import PathImgCelebration_014_001 from '../images_categories/celebrations/img_014_001.jpg';
import PathImgCelebration_014_004 from '../images_categories/celebrations/img_014_004.jpg';
import PathImgCelebration_014_005 from '../images_categories/celebrations/img_014_005.jpg';


import PathImgBabyshower_001 from '../images_categories/babyshower/img_001.jpg';
import PathImgBabyshower_002 from '../images_categories/babyshower/img_002.jpg';
import PathImgBabyshower_003 from '../images_categories/babyshower/img_003.jpg';
import PathImgBabyshower_004_001 from '../images_categories/babyshower/img_004_01.jpg';
import PathImgBabyshower_004_002 from '../images_categories/babyshower/img_004_02.jpg';
import PathImgBabyshower_005_001 from '../images_categories/babyshower/img_005_001.jpg';
import PathImgBabyshower_005_002 from '../images_categories/babyshower/img_005_002.jpg';
import PathImgBabyshower_005_003 from '../images_categories/babyshower/img_005_003.jpg';
import PathImgBabyshower_005_004 from '../images_categories/babyshower/img_005_004.jpg';
import PathImgBabyshower_005_005 from '../images_categories/babyshower/img_005_005.jpg';
import PathImgBabyshower_005_006 from '../images_categories/babyshower/img_005_006.jpg';
import PathImgBabyshower_006_001 from '../images_categories/babyshower/img_006_001.jpg';
import PathImgBabyshower_006_002 from '../images_categories/babyshower/img_006_002.jpg';
import PathImgBabyshower_006_003 from '../images_categories/babyshower/img_006_003.jpg';
import PathImgBabyshower_006_004 from '../images_categories/babyshower/img_006_004.jpg';
import PathImgBabyshower_006_005 from '../images_categories/babyshower/img_006_005.jpg';
import PathImgBabyshower_007_002 from '../images_categories/babyshower/img_007_002.jpg';
import PathImgBabyshower_007_003 from '../images_categories/babyshower/img_007_003.jpg';
import PathImgBabyshower_007_004 from '../images_categories/babyshower/img_007_004.jpg';
import PathImgBabyshower_007_005 from '../images_categories/babyshower/img_007_005.jpg';


import PathImgBaptism001 from '../images_categories/baptism/img_001.jpg';
import PathImgBaptism002 from '../images_categories/baptism/img_002.jpg';
import PathImgBaptism003 from '../images_categories/baptism/img_003.jpg';


import PathImgWedding001 from '../images_categories/wedding/img_001.jpg';
import PathImgWedding002S01 from '../images_categories/wedding/img_002_01.jpg';
import PathImgWedding002S02 from '../images_categories/wedding/img_002_02.jpg';
import PathImgWedding002S03 from '../images_categories/wedding/img_002_03.jpg';


const BIRTH = [
    {
        src: PathImgBirth01_009_001,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey
    },
    {
        src: PathImgBirth01_009_002,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey
    },
    {
        src: PathImgBirth01_009_003,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey
    },
    {
        src: PathImgBirth01_009_004,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey
    },
    {
        src: PathImgBirth01_009_007,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey
    },
    {
        src: PathImgBirth_036_001,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth_036_002,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth_036_003,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth_036_004,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth50G_001_001,
        category: CATEGORY.birth_fifty_greater_years
    },
    {
        src: PathImgBirth50G_001_002,
        category: CATEGORY.birth_fifty_greater_years
    },
    {
        src: PathImgBirth50G_001_003,
        category: CATEGORY.birth_fifty_greater_years
    },
    {
        src: PathImgBirth50G_005_001,
        category: CATEGORY.birth_fifty_greater_years
    },
    {
        src: PathImgBirth50G_005_002,
        category: CATEGORY.birth_fifty_greater_years
    },
    {
        src: PathImgBirth01_007_001,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.winnieThePooh
    },
    {
        src: PathImgBirth01_007_002,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.winnieThePooh
    },
    {
        src: PathImgBirth01_007_003,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.winnieThePooh
    },
    {
        src: PathImgBirth01_007_005,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.winnieThePooh
    },
    {
        src: PathImgBirth01_007_009,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.winnieThePooh
    },
    {
        src: PathImgBirth15_004_001,
        category: CATEGORY.birth_fifteen_years
    },
    {
        src: PathImgBirth0214_018_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.frozenPrincess
    },
    {
        src: PathImgBirth0214_018_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.frozenPrincess
    },
    {
        src: PathImgBirth0214_018_004,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.frozenPrincess
    },
    {
        src: PathImgBirth0214_018_006,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.frozenPrincess
    },
    {
        src: PathImgBirth_001_001,
        category: CATEGORY.birth_others,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth_001_002,
        category: CATEGORY.birth_others,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth_001_003,
        category: CATEGORY.birth_others,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth_001_006,
        category: CATEGORY.birth_others,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth0214_003_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Un día maravilloso"
    },
    {
        src: PathImgBirth0214_003_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Un día maravilloso"
    },
    {
        src: PathImgBirth0214_003_006,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Un día maravilloso"
    },
    {
        src: PathImgBirth0214_003_007,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Un día maravilloso"
    },
    {
        src: PathImgBirth0214_003_008,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Un día maravilloso"
    },
    {
        src: PathImgBirth0214_003_010,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Un día maravilloso"
    },
    {
        src: PathImgBirth50G_002_001,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_002_002,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_002_003,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_002_004,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth0214_034_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.neon,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth0214_034_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.neon,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth0214_034_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.neon,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth15_006_001,
        category: CATEGORY.birth_fifteen_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth15_006_002,
        category: CATEGORY.birth_fifteen_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth15_006_003,
        category: CATEGORY.birth_fifteen_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth15_006_007,
        category: CATEGORY.birth_fifteen_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth0214_015_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.frozenPrincess
    },
    {
        src: PathImgBirth0214_015_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.frozenPrincess
    },
    {
        src: PathImgBirth0214_015_004,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.frozenPrincess
    },
    {
        src: PathImgBirth0214_017_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.farm
    },
    {
        src: PathImgBirth0214_017_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.farm
    },
    {
        src: PathImgBirth0214_017_004,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.farm
    },
    {
        src: PathImgBirth50G_009_004,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth50G_009_002,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth50G_010_001,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth50G_010_002,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth0214_029_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.mermaid,
        description: "Celebrando los seis años de la pequeña Gloriia."
    },
    {
        src: PathImgBirth0214_029_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.mermaid,
        description: "Celebrando los seis años de la pequeña Gloriia."
    },
    {
        src: PathImgBirth0214_029_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.mermaid,
        description: "Celebrando los seis años de la pequeña Gloriia."
    },
    {
        src: PathImgBirth0214_006_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.skibidiToilet
    },
    {
        src: PathImgBirth0214_006_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.skibidiToilet
    },
    {
        src: PathImgBirth0214_014_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.kuromi
    },
    {
        src: PathImgBirth0214_014_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.kuromi
    },
    {
        src: PathImgBirth0214_014_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.kuromi
    },
    {
        src: PathImgBirth15_005_001,
        category: CATEGORY.birth_fifteen_years,
        title: THEMATIC.jazminPrincess,
        description: "En los quince años de la señorita Yasmín."
    },
    {
        src: PathImgBirth_002_001,
        category: CATEGORY.birth_others,
        title: THEMATIC.custom,
    },
    {
        src: PathImgBirth_002_002,
        category: CATEGORY.birth_others,
        title: THEMATIC.custom,
    },
    {
        src: PathImgBirth_002_003,
        category: CATEGORY.birth_others,
        title: THEMATIC.custom,
    },
    {
        src: PathImgBirth01_005_001,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey,
        description: "El primer añito del pequeño Patrick."
    },
    {
        src: PathImgBirth01_005_004,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey,
        description: "El primer añito del pequeño Patrick."
    },
    {
        src: PathImgBirth50G_011_001,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth50G_011_003,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom,
        description: "Un día inolvidable"
    },
    {
        src: PathImgBirth01_002_001,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.farm,
        description: "El primer añito de la pequeña Rafaella."
    },
    {
        src: PathImgBirth01_002_002,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.farm,
        description: "El primer añito de la pequeña Rafaella."
    },
    {
        src: PathImgBirth0214_028_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.minnie,
        description: "El primer añito de la pequeña Valentina."
    },
    {
        src: PathImgBirth0214_032_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Los ocho años de la pequeña Danella."
    },
    {
        src: PathImgBirth0214_032_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Los ocho años de la pequeña Danella."
    },
    {
        src: PathImgBirth0214_032_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Los ocho años de la pequeña Danella."
    },
    {
        src: PathImgBirth0214_032_004,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Los ocho años de la pequeña Danella."
    },
    {
        src: PathImgBirth01_004_001,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.safari,
        description: "Celebrando el primer añito de Elian con la temática Safari."
    },
    {
        src: PathImgBirth01_004_003,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.safari,
        description: "Celebrando el primer añito de Elian con la temática Safari."
    },
    {
        src: PathImgBirth01_004_004,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.safari,
        description: "Celebrando el primer añito de Elian con la temática Safari."
    },
    {
        src: PathImgBirth0214_001_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.neon,
        description: "Celebrando los catorce años de Diego con la temática neón."
    },
    {
        src: PathImgBirth0214_001_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.neon,
        description: "Celebrando los catorce años de Diego con la temática neón."
    },
    {
        src: PathImgBirth0214_001_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.neon,
        description: "Celebrando los catorce años de Diego con la temática neón."
    },
    {
        src: PathImgBirth0214_004_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.spiderman
    },
    {
        src: PathImgBirth0214_004_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.spiderman
    },
    {
        src: PathImgBirth0214_004_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.spiderman
    },
    {
        src: PathImgBirth0214_004_004,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.spiderman
    },
    {
        src: PathImgBirth50G_007_001,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_007_002,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_007_003,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_007_004,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_007_005,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth0214_009,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.frozenPrincess,
        description: "Un día inolvidable en el tercer cumpleaños de la pequeña Ariana."
    },
    {
        src: PathImgBirth0214_019,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.theGiftOfFamily,
        description: "Celebrando el cumpleaños de la pequeña Mariel."
    },
    {
        src: PathImgBirth0214_025_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.rapunzelPrincess,
        description: "Festejando el octavo cumpleaños de la pequeña Irisa."
    },
    {
        src: PathImgBirth0214_025_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.rapunzelPrincess,
        description: "Festejando el octavo cumpleaños de la pequeña Irisa."
    },
    {
        src: PathImgBirth01_008_001,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.zoo
    },
    {
        src: PathImgBirth01_008_002,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.zoo
    },
    {
        src: PathImgBirth01_008_003,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.zoo
    },
    {
        src: PathImgBirth01_008_004,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.zoo
    },
    {
        src: PathImgBirth50G_003_001,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_003_002,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_003_003,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_003_004,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_003_005,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth50G_003_006,
        category: CATEGORY.birth_fifty_greater_years,
        title: THEMATIC.custom
    },
    {
        src: PathImgBirth0214_008_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.heny
    },
    {
        src: PathImgBirth0214_008_002,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.heny
    },
    {
        src: PathImgBirth0214_008_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.heny
    },
    {
        src: PathImgBirth_031_001,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth_031_002,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth01_003_001,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey,
        description: "Festejando los tres meses del bebé Adriel."
    },
    {
        src: PathImgBirth01_003_002,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey,
        description: "Festejando los tres meses del bebé Adriel."
    },
    {
        src: PathImgBirth01_003_003,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mickey,
        description: "Festejando los tres meses del bebé Adriel."
    },
    {
        src: PathImgBirth01_001,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.mermaid,
        description: "Celebramos el primer añito de la pequeña Ally."
    },
    {
        src: PathImgBirth0214_022,
        category: CATEGORY.birth_two_fourteen_years
    },
    {
        src: PathImgBirth0214_016_001,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.pawPatroll,
        description: "Celebramos el segundo añito del pequeño Constantino."
    },
    {
        src: PathImgBirth0214_016_003,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.pawPatroll,
        description: "Celebramos el segundo añito del pequeño Constantino."
    },
    {
        src: PathImgBirth0214_016_005,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.pawPatroll,
        description: "Celebramos el segundo añito del pequeño Constantino."
    },
    {
        src: PathImgBirth0214_005,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.sofiaPrincess,
        description: "Un día inolvidable."
    },
    {
        src: PathImgBirth_008,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth0214_035_001,
        category: CATEGORY.birth_two_fourteen_years
    },
    {
        src: PathImgBirth0214_035_002,
        category: CATEGORY.birth_two_fourteen_years
    },
    {
        src: PathImgBirth0214_035_003,
        category: CATEGORY.birth_two_fourteen_years
    },
    {
        src: PathImgBirth15_003_001,
        category: CATEGORY.birth_fifteen_years
    },
    {
        src: PathImgBirth15_003_002,
        category: CATEGORY.birth_fifteen_years
    },
    {
        src: PathImgBirth0214_021,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.farm,
        description: "Un día inolvidable."
    },
    {
        src: PathImgBirth0214_033,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.mario,
        description: "Celebramos el cumpleaños del pequeño Jackson con la temática de Mario Bros!"
    },
    {
        src: PathImgBirth0214_011,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.mermaid,
        description: "Celebramos los siete años de la pequeña Gia con la temática de La Sirenita!"
    },
    {
        src: PathImgBirth_015,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth01_025,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.safari,
        description: "Felicidades Rafael por el día de tu primer añito!"
    },
    {
        src: PathImgBirth01_028,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.farm,
        description: "Felicidades Juan por el día de tu primer añito!"
    },
    {
        src: PathImgBirth01_032,
        category: CATEGORY.birth_one_year
    },
    {
        src: PathImgBirth_029,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth0214_012,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.pokemon,
        description: "Festejando el octavo cumpleaños de Mateo con la temática de Pokemon!"
    },
    {
        src: PathImgBirth0214_007,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.disneyPrincess,
        description: "Festejando el septimo cumpleaños de Tahira con la temática de princesas Disney!"
    },
    {
        src: PathImgBirth01_006_001,
        category: CATEGORY.birth_one_year
    },
    {
        src: PathImgBirth01_006_002,
        category: CATEGORY.birth_one_year
    },
    {
        src: PathImgBirth01_006_003,
        category: CATEGORY.birth_one_year
    },
    {
        src: PathImgBirth50G_001,
        category: CATEGORY.birth_fifty_greater_years
    },
    {
        src: PathImgBirth0214_010,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.mickey,
        description: "Festejamos los dos añitos de Isaac con la temática de Mickey mouse!"
    },
    {
        src: PathImgBirth_020,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth15_001,
        category: CATEGORY.birth_fifteen_years
    },
    {
        src: PathImgBirth0214_023,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.barbie,
        description: "Festejamos el cumpleaños de Brianna con la temática de Barbie!"
    },
    {
        src: PathImgBirth0214_026,
        category: CATEGORY.birth_two_fourteen_years
    },
    {
        src: PathImgBirth0214_024,
        category: CATEGORY.birth_two_fourteen_years
    },
    {
        src: PathImgBirth50G_006,
        category: CATEGORY.birth_fifty_greater_years
    },
    {
        src: PathImgBirth_014,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth0214_013,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.spiderman,
        description: "Festejamos los cinco añitos de Mateo con la temática de Spiderman!"
    },
    {
        src: PathImgBirth01_016,
        category: CATEGORY.birth_one_year,
        title: THEMATIC.farm,
        description: "Festejamos el primer añito de Isacc con la temática de la Granja!"
    },
    {
        src: PathImgBirth_017,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth_018,
        category: CATEGORY.birth_others
    },
    {
        src: PathImgBirth50G_004,
        category: CATEGORY.birth_fifty_greater_years
    },
    {
        src: PathImgBirth0214_030,
        category: CATEGORY.birth_two_fourteen_years,
        title: THEMATIC.sofiaPrincess,
        description: "Celebrando el cumpleaños de sophia con la temática de la princesa Sofia!"
    },
    {
        src: PathImgBirth15_002,
        category: CATEGORY.birth_fifteen_years
    }
]

const CELEBRATIONS = [
    { src: PathImgCelebration_011_007 },
    { src: PathImgCelebration_011_001 },
    { src: PathImgCelebration_011_002 },
    { src: PathImgCelebration_011_003 },
    { src: PathImgCelebration006 },
    { src: PathImgCelebration_013_001 },
    { src: PathImgCelebration_013_002 },
    { src: PathImgCelebration_013_003 },
    { src: PathImgCelebration_008_001 },
    { src: PathImgCelebration_008_002 },
    { src: PathImgCelebration_008_004 },
    { src: PathImgCelebration001 },
    { src: PathImgCelebration005S00 },
    { src: PathImgCelebration005S01 },
    { src: PathImgCelebration005S02 },
    { src: PathImgCelebration005S04 },
    { src: PathImgCelebration_009_001 },
    { src: PathImgCelebration_009_003 },
    { src: PathImgCelebration_010_001 },
    { src: PathImgCelebration_010_002 },
    { src: PathImgCelebration_010_003 },
    { src: PathImgCelebration_014_001 },
    { src: PathImgCelebration_014_004 },
    { src: PathImgCelebration_014_005 },
    { src: PathImgCelebration004 },
    { src: PathImgCelebration_012_001 },
    { src: PathImgCelebration_012_002 },
    { src: PathImgCelebration002 },
    { src: PathImgCelebration003 },
]

const BABYSHOWER = [
    { src: PathImgBabyshower_005_001 },
    { src: PathImgBabyshower_005_002 },
    { src: PathImgBabyshower_005_003 },
    { src: PathImgBabyshower_005_004 },
    { src: PathImgBabyshower_005_005 },
    { src: PathImgBabyshower_005_006 },
    { src: PathImgBabyshower_004_001 },
    { src: PathImgBabyshower_004_002 },
    { src: PathImgBabyshower_006_001 },
    { src: PathImgBabyshower_006_002 },
    { src: PathImgBabyshower_006_003 },
    { src: PathImgBabyshower_006_004 },
    { src: PathImgBabyshower_006_005 },
    { src: PathImgBabyshower_001 },
    { src: PathImgBabyshower_007_005 },
    { src: PathImgBabyshower_007_002 },
    { src: PathImgBabyshower_007_003 },
    { src: PathImgBabyshower_007_004 },
    { src: PathImgBabyshower_003 },
    { src: PathImgBabyshower_002 }
]

const BAPTISM = [
    { src: PathImgBaptism001 },
    { src: PathImgBaptism003 },
    { src: PathImgBaptism002 }
]

const WEDDING = [
    { src: PathImgWedding002S01 },
    { src: PathImgWedding002S02 },
    { src: PathImgWedding002S03 },
    { src: PathImgWedding001 }
]



const getBirthFiltered = (category) => {
    var arrayFiltered = []
    if (category === CATEGORY.birth_all) {
        arrayFiltered = BIRTH;
    }
    if (category === CATEGORY.birth_one_year) {
        arrayFiltered = BIRTH.filter(filterByOneYear);
    }
    if (category == CATEGORY.birth_two_fourteen_years) {
        arrayFiltered = BIRTH.filter(filterByTwoForteenYears);
    }
    if (category === CATEGORY.birth_fifteen_years) {
        arrayFiltered = BIRTH.filter(filterByFifeteen);
    }
    if (category === CATEGORY.birth_fifty_greater_years) {
        arrayFiltered = BIRTH.filter(filterByFiftyGreater);
    }
    if (category === CATEGORY.birth_others) {
        arrayFiltered = BIRTH.filter(filterByOthers);
    }
    return arrayFiltered;
}

function filterByOneYear(item) {
    console.log(item)
    if (item.category == CATEGORY.birth_one_year) {
      return true;
    }
    return false;
}

function filterByTwoForteenYears(item) {
    console.log(item)
    if (item.category == CATEGORY.birth_two_fourteen_years) {
      return true;
    }
    return false;
}

function filterByFifeteen(item) {
    console.log(item)
    if (item.category == CATEGORY.birth_fifteen_years) {
      return true;
    }
    return false;
}

function filterByFiftyGreater(item) {
    console.log(item)
    if (item.category == CATEGORY.birth_fifty_greater_years) {
      return true;
    }
    return false;
}

function filterByOthers(item) {
    console.log(item)
    if (item.category == CATEGORY.birth_others) {
      return true;
    }
    return false;
}

export {
    BIRTH,
    CELEBRATIONS,
    BABYSHOWER,
    BAPTISM,
    WEDDING,
    getBirthFiltered
}