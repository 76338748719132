import {CATEGORY} from './constants.js';

const BIRTH_FILTER_MODAL = {
    id: 'modalBirthFilter',
    title: 'Selecciona una categoría',
    filterOptions: [
        {
            label: 'Ver todos',
            value: CATEGORY.birth_all
        },
        {
            label: '1 año',
            value: CATEGORY.birth_one_year
        },
        {
            label: '2 a 14 años',
            value: CATEGORY.birth_two_fourteen_years
        },
        {
            label: '15 años',
            value: CATEGORY.birth_fifteen_years
        },
        {
            label: '50 años y mayores',
            value: CATEGORY.birth_fifty_greater_years
        },
        {
            label: 'Otros',
            value: CATEGORY.birth_others
        }
    ]
}

export {
    BIRTH_FILTER_MODAL
}