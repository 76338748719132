import React from 'react';
import styled from 'styled-components';
import { Footer as Feet } from 'react-materialize';
import { COLOR, LABEL, SIZE, SCREEN_MEDIA, URL, FONT_FAMILY } from '../../utils/constants.js';
import { Label } from '../atoms/label.jsx';
import { Container, Row, Col } from 'react-materialize';
import TextButton from '../atoms/textbutton.jsx';
import SVG from 'react-inlinesvg';

import PathLogo from '../../images/img_logo_bg_green.svg';
import PathIcFbColored from '../../icons/ic_fb_colored.svg';
import PathIcWappColored from '../../icons/ic_wapp_colored.svg';
import PathIcInstagramColored from '../../icons/ic_instagram_colored.svg';
import PathIcEmailColored from '../../icons/ic_email_colored.svg';
import PathBg from '../../images/img_footer.jpg';

const current_year = new Date().getFullYear();
const copyright_label = "Copyright \xA9" + current_year.toString() + " Todos los derechos reservados";


const List = styled.ul`
    margin-top: 40px;
    li {
        margin-bottom: 20px;
        display: flex; // To avoid purple padding on <a> tag
    }
`;

const links = 
<List>
    <li>
        <TextButton 
            href={URL.whatsapp}
            target="_blank"
            ic_path={PathIcWappColored}
            ic_size='35px'
            text_color={COLOR.ferra}
            text_size={SIZE.subtitle}
            font_family={FONT_FAMILY.noteworthyBold}>
                WhatsApp
        </TextButton>
    </li>
    <li>
        <TextButton 
            href={URL.facebook}
            target="_blank"
            ic_path={PathIcFbColored}
            ic_size='35px'
            text_color={COLOR.ferra}
            text_size={SIZE.subtitle}
            font_family={FONT_FAMILY.noteworthyBold}>
                Facebook
        </TextButton>
    </li>
    <li>
        <TextButton 
            href={URL.instagram}
            target="_blank"
            ic_path={PathIcInstagramColored}
            ic_size='35px'
            text_color={COLOR.ferra}
            text_size={SIZE.body}
            font_family={FONT_FAMILY.noteworthyBold}>
                Instagram
        </TextButton>
    </li>
    <li>
        <TextButton 
            href={URL.email}
            ic_path={PathIcEmailColored}
            ic_size='35px'
            text_color={COLOR.ferra}
            text_size={SIZE.subtitle}
            font_family={FONT_FAMILY.noteworthyBold}>
                {LABEL.contact}
        </TextButton>
    </li>
</List>;


const FeetStyled = styled(Feet)`
    margin: 100px 0 0 0;
    background-image: url(${PathBg});
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: relative;
    z-index: 0;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: #F9FCED;
        opacity: 0.85;
    }
`;

const LogoContent = styled.div`
    margin: 40px 0 20px 0;
`;

const Logo = styled(SVG)`
    margin: auto;
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 160px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 160px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        height: 160px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        height: 200px;
    }
`;

const Footer = () => (
    <FeetStyled
        copyrights={copyright_label}
        links={links} >
        <Container>
            <Row>
                <Col>
                    <LogoContent>
                        <Logo src={PathLogo} />
                    </LogoContent>
                </Col>
                <Col>
                    <Label
                        text_color={COLOR.ferra}
                        text_size={SIZE.subtitle}
                        font_family={FONT_FAMILY.noteworthyBold}>
                        Síguenos en nuestras redes sociales y no te pierdas nuestras publicaciones.
                    </Label>
                </Col>
            </Row>
        </Container>
    </FeetStyled>
);

export default Footer;